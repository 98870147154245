import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Row, Table } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NikkeGuidesOLIntro: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Overload Gear - Re-Rolling Effects and Attributes</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_ol.webp"
            alt="Liberation"
          />
        </div>
        <div className="page-details">
          <h1>NIKKE Overload Gear (OL) - Rerolling Effects and Attributes</h1>
          <h2>
            Guide for the Overload Gear (OL) in NIKKE - Rerolling Effects and
            Attributes.
          </h2>
          <p>
            Last updated: <strong>24/06/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content ">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Foreword" />
              <p>
                Previously, we explained how to OL, what each OL does, and
                provided a list of recommendations for each unit. However, how
                should I exactly arrange them on my gear? How do I use my rocks
                to get all of them on my gear? It is almost mathematically and
                financially impossible to be ridiculously lucky and acquire the
                perfect combination on each gear we have, but we can definitely
                try to reach near to that perfection. So, we have prepared
                several guidelines below that will help you minimise loss and
                obtain the best rolls in exchange for our finite quantity of
                resources, but it all still depends on your luck.
              </p>
              <p>
                For more information, check our other guides in the Overload
                series:
              </p>
              <Row
                xs={1}
                md={2}
                lg={2}
                xxl={2}
                className="g-3 category-cards-container"
              >
                <CategoryCard
                  title="OL Gear - Intro"
                  link="/nikke/guides/overload-gear-intro"
                  image={
                    <StaticImage
                      src="../../../images/nikke/categories/category_ol.webp"
                      alt="Overload Gear - Intro"
                    />
                  }
                />
                <CategoryCard
                  title="OL Gear - Recommendations"
                  link="/nikke/guides/overload-gear-recommendations"
                  image={
                    <StaticImage
                      src="../../../images/nikke/categories/category_olrec.jpg"
                      alt="OL Gear - Recommendations"
                    />
                  }
                />
              </Row>
              <SectionHeader title="Introduction" />
              <p>
                As before, we will separate our Overload Targets into three
                classifications: Essential, Ideal, and Passable.
              </p>
              <ul>
                <li>
                  <strong>Essential</strong>: With all gear combined, your unit
                  must have the roll(s) prescribed as a minimum requirement.
                  Example: 2× Charge Speed on Alice.
                </li>
                <li>
                  <strong>Ideal</strong>: These rolls will improve your unit's
                  performance significantly. Aim for 2 lines of Essential/Ideal
                  per gear for maximum performance and the best Return on
                  Investment (ROI). If you get 3, you are lucky. You can choose
                  to only have 1 as well to save materials. Example: Elemental
                  Damage on Scarlet.
                </li>
                <li>
                  <strong>Passable:</strong> Low-priority line that has either
                  limited value or the impact is not as strong as other rolls.
                  Should this show up as a bonus together with a better line,
                  you can choose to keep them over other lines. Example: Charge
                  Speed on Red Hood.
                </li>
              </ul>
              <p>
                In a perfect world, we want our gear pieces to have at least 2
                good rolls, where both belong to the Essential and/or Ideal
                groups. However, this is not a must as it is indeed more costly
                than having 1 good roll, which can be preferable in some
                situations, such as when the extra rolls are unnecessary or not
                that impactful, and you want to conserve rocks.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Before You Start" />
              <p>
                Before we go into Rerolling, there are few pieces of information
                we believe to be common knowledge about re-rolling.
              </p>
              <p>
                <strong>#1</strong>. There are two types of Rerolling.
              </p>
              <h5>
                <FontAwesomeIcon icon={faRotate} width="18" /> Change Effects
              </h5>
              <p>
                Using this option to Reroll changes the effects completely. Both
                Effects and Attributes are changed. To understand how to change
                effects, we have an example below.
              </p>
              <StaticImage
                src="../../../images/nikke/generic/ol_r_1.webp"
                alt="Guide"
              />
              <p>
                Here, we are Re-Rolling effects on B.Soda in order to get her to
                do more damage. We press Change Effects (Circled in Red in first
                two images) in order to change the effects we currently have.
              </p>
              <p>
                Focusing on the right most image, in this case we had Line 1 Hit
                Rate (Red Underline above), which turned into 1 Charge Damage on
                Line 1 (Red Underline below). Line 3 (Green underline above) did
                not have an effect before, but now we have Critical Rate (Green
                Underline below) on Line 3. We see that Line 2 (Blue Underline)
                still did not give us new effects, since we were unlucky.
              </p>
              <p>
                Now we face two choices. Keep (Circled in Red) or Change
                (Circled in Green) effects. Since Charge Damage is useless on
                Shotguns, we compare Critical Rate and Hit Rate for B.Soda. Both
                are considered as Passable effects for B.Soda, so now we compare
                tiers. Since Hit Rate is of a higher tier than Critical Rate, we
                will choose to Keep Effects! In cases where you get better
                effects, we would choose to Change Effects!
              </p>
              <h5>% Reset Attributes</h5>
              <p>
                Using this option to Reroll changes only the percentage (%) of
                the effects. The type of effect always remains unchanged, and a
                new effect does not appear if an effect has not been obtained in
                a line.
              </p>
              <p>Example:</p>
              <StaticImage
                src="../../../images/nikke/generic/ol_r_2.webp"
                alt="Guide"
              />
              <p>
                Here, we are Rerolling attributes on B.Soda in order to get her
                to do more damage. We press Reset Attributes (Circled in Red in
                first two images) in order to change the percentage (%) of the
                buff we currently have. Each effect has 15 Tiers of Attributes.
                The higher the tier, the higher the %.
              </p>
              <p>
                Focusing on the right most image, in this case we had Line 1 Hit
                Rate (Red Underline above) with 9.70% (Tier 8 buff). The
                percentage upgrades to 11.11% (Tier 10 buff) (Red Underline
                below). Line 2 (Blue underline above) was a 9.54% (Tier 1)
                Elemental Damage before, but now we have a 13.75% (Tier 4)
                Elemental Damage line. We now see that not only did Line 3
                (Green Underline) not give us new effects, but none of the other
                effects changed as well. Only the percentage (%) of the buff
                effects changed.
              </p>
              <p>
                Now we face two choices. Keep (Circled in Red) or Change
                (Circled in Green) effects. Since both effects received an
                increase in percentage, we will choose to Change Effects! We
                would do the same if only Elemental Damage Tier had increased,
                but Hit Rate had decreased, since Elemental is an Ideal effect
                for B.Soda while Hit Rate is a Passable effect. In cases where
                you get lower percentages, you may choose to Keep Effects! We
                would also choose to Keep Effects if Hit Rate had increased, but
                Elemental Damage had decreased, since Elemental is an Ideal
                effect for B.Soda while Hit Rate is a Passable effect.{' '}
              </p>
              <p>
                <strong>#2</strong>. A Blue Text Line effect is a very high tier
                roll with a very low chance of appearing. These are Tier
                12/13/14 rolls with high values.
              </p>
              <StaticImage
                src="../../../images/nikke/generic/ol_r_3.webp"
                alt="Guide"
              />
              <p>
                <strong>#3</strong>. A Black Line effect is the highest tier
                roll of that effect. These are Tier 15 rolls, and are also
                extremely rare (1% chance of happening).
              </p>
              <StaticImage
                src="../../../images/nikke/generic/ol_r_4.webp"
                alt="Guide"
              />
              <p>
                <strong>#4</strong>. Overload your units in this order to gain
                the highest ATK possible in case you do not have enough to OL
                all four gear pieces:
              </p>
              <p>
                <strong>Head &gt; Arm &gt; Body &gt; Leg</strong>
              </p>
              <p>
                <strong>#5</strong>. In case the unit scales well with HP (e.g.
                2B) or DEF (e.g. Mast) instead of ATK, the order of upgrading
                should be:
              </p>
              <ul>
                <li>HP: Body &gt; Head &gt; Leg &gt; Arm</li>
                <li>DEF: Leg &gt; Arm &gt; Head &gt; Body</li>
              </ul>
              <p>
                <strong>#6</strong>. Reroll DPS before moving on to supports,
                unless the support has a Essential requirement (e.g 1× Max Ammo
                on D: Killer Wife, or making sure you reroll away any Max Ammo
                you have on your Dorothy).
              </p>
              <p>
                <strong>#7</strong>. If a requirement is explicitly listed, such
                as in xLudmilla's case where Ammo needs to be 120%, the % of
                multiple rolls from all OL pieces needs to add up to that
                number.
              </p>
              <p>
                <strong>#8</strong>. In cases where requirement is not listed,
                there is a minimum acceptable Attribute % value for each roll,
                which is usually considered to be what we count as 1× of the
                effect. If you have lower values than the ones listed, they can
                be considered to be 0.5× of the effect. Example: 2× Tier 1/2/3
                Rolls of Max Ammo are what we consider as only 1× Max Ammo Roll
                on any unit. Here is what we consider minimum acceptable
                Attributes to be:
              </p>
              <Table striped bordered responsive>
                <thead>
                  <tr>
                    <th>Effect</th>
                    <th>Minimum Attribute (%)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Elemental Damage</td>
                    <td>19.35%</td>
                  </tr>
                  <tr>
                    <td>Max Ammo</td>
                    <td>52.50%</td>
                  </tr>
                  <tr>
                    <td>ATK</td>
                    <td>9.70%</td>
                  </tr>
                  <tr>
                    <td>Charge Speed</td>
                    <td>4.04%</td>
                  </tr>
                  <tr>
                    <td>Hit Rate</td>
                    <td>9.70%</td>
                  </tr>
                  <tr>
                    <td>Charge Damage</td>
                    <td>Does not matter</td>
                  </tr>
                  <tr>
                    <td>Crit Rate</td>
                    <td>Does not matter</td>
                  </tr>
                  <tr>
                    <td>Crit Damage</td>
                    <td>Does not matter</td>
                  </tr>
                  <tr>
                    <td>DEF</td>
                    <td>Does not matter</td>
                  </tr>
                </tbody>
              </Table>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="How to Reroll Efficiently" />
              <p>
                This is everyone's ultimate question. Even if we exactly know
                what to roll for our characters, our luck is not pristine. We
                need to make compromises. In this section, we will talk about,
                based on mathematical grounds, what is the most economical way
                to reroll your equipment so that you can achieve your intended
                result with the fewest resources possible. Should you lock your
                Essential/Ideal line? Should you refresh the attributes first?
                Be informed that, while our tips are theoretically accurate,
                your mileage may vary in the real world. After all, it is a luck
                based system.
              </p>
              <p>
                For the sake of simplicity, we will be breaking down Re-Rolling
                into Categories and Situations which you may find yourselves in.
                Situations might have Follow Up questions which can give
                different ways of Re-Rolling. We will be discussing 3 categories
                of gear pieces. Each Category will have specific situations that
                you will always find yourself in, which will have answers on how
                to progress:
              </p>
              <ul>
                <li>
                  Category 1: The gear piece needs 1 Essential or Ideal effect.
                </li>
                <li>
                  Category 2: The gear piece needs 2 Essential and/or Ideal
                  effects.
                </li>
                <li>
                  Category 3: The gear piece needs 3 Essential and/or ideal
                  effects.
                </li>
              </ul>
              <h5>Rerolling and it's Situations</h5>
              <p>
                Start rerolling with the worst piece. Knowing which gear piece
                to reroll is one of the most important steps to improving your
                Nikke's damage output.
              </p>
              <ol>
                <li>
                  If the piece has no Essential or Ideal effects, that piece is
                  the best choice to reroll on.
                </li>
                <li>
                  If the piece happens to have one or more Essential/Ideal
                  effects, refer to one of the situations explained below.
                </li>
                <li>
                  If the piece already has all the desired effects, have one
                  last check to see if they are below the minimum requirement.
                </li>
              </ol>
              <h5>
                Category 1: The gear piece needs 1 Essential or Ideal effect.
              </h5>
              <StaticImage
                src="../../../images/nikke/generic/ol_reroll_1.webp"
                alt="Guide"
              />
              <p>
                This category can be used when you are only targeting one Effect
                on a gear, such as if you want to make sure that a unit just has
                those essential rolls in order to make them usable units (e.g
                Max Ammo for Scarlet). Another reason to use this method of
                rerolling is when the unit only needs one specific effect, the
                example being as follows.
              </p>
              <ol>
                <li>
                  Worked Example: <strong>D:Killer Wife</strong>
                </li>
                <li>Essential: Max Ammo (1×)</li>
                <li>Ideal: -</li>
                <li>Passable: -</li>
              </ol>
              <p>
                TLDR: The best way to achieve 1 Essential/Ideal effect on a
                piece is to change effects, without locking, until you get the
                desired roll. Reset attributes if necessary.
              </p>
              <ul>
                <li>
                  <strong>Picking the best gear to reroll on</strong>: There is
                  only one possible situation in this case, which is that none
                  of the 4 gears have a Max Ammo Capacity Effect on any of the
                  lines. Pick one of the gears which preferably has no ATK,
                  Elemental Damage, or Charge Speed lines. If all of the gears
                  have one or two of these lines, then just pick any gear. Let's
                  pick the head gear piece in this situation.
                </li>
                <li>
                  <strong>Cost</strong>: 1 Custom Module per reroll.
                </li>
                <li>
                  <strong>How to Reroll</strong>: The target for our gear is 1
                  Max Ammo Line. Do not lock any line. Change Effects until you
                  get a Max Ammo line on any of the 3 lines of your head piece
                  gear.
                </li>
                <li>
                  <strong>Rerolling Attributes</strong>: After you have gotten
                  that Max Ammo Line, your D should have 8 or more than 8 Ammo,
                  hence there is no need to reroll her attributes. You are now
                  done rerolling this gear piece.
                </li>
              </ul>
              <hr />
              <h5>
                Category 2: The gear piece needs 2 Essential and/or Ideal
                effects.
              </h5>
              <StaticImage
                src="../../../images/nikke/generic/ol_reroll_2.webp"
                alt="Guide"
              />
              <p>
                This category should be the most important category for most
                players, since having 2 good effects per gear is what most
                players aim for as a bare minimum. Even if the Nikke has a need
                of 3 Essential/Ideal effects per piece, having 2 of 3 important
                rolls is still absolutely fine in 90% of the situations, and is
                enough to compete for a top 1% spot in Solo Raids. It is the
                most cost effective method to build a Nikke.
              </p>
              <ul>
                <li>
                  Worked Example: <strong>Snow White</strong>
                </li>
                <li>Essential: -</li>
                <li>Ideal: 4× Elemental Damage, 4× ATK</li>
                <li>Passable: 4× Crit Rate, 4× Crit Damage</li>
              </ul>
              <p>
                TLDR: The best way to achieve 2 Essential/Ideal effects is to
                change effects, without locking, until you get one of the two
                effects on line 2 or line 3. Then Reset attributes if necessary.
                Then lock this line and change effects until you get the second
                desired effect on the other 2 lines. Reset attributes if
                necessary again.
              </p>
              <h6>Situation 1</h6>
              <p>
                I have none of the Ideal/Essential effects on my selected gear
                piece.
              </p>
              <p>
                This is the best piece to reroll on since it has none of our
                desired effects and is the easiest and cheapest to improve. It
                is even better if the gear does not have any of the Passable
                lines, but even if all of them do, it is fine to reroll them
                away. Remember, Passable lines are supposed to be bonus lines,
                and not something to aim for, hence they can be ignored.
              </p>
              <ul>
                <li>
                  <strong> How to reroll:</strong> The target for our gear is 1
                  Elemental Line and 1 ATK line. Do not lock any line. Change
                  Effects till you have one of the Ideal effects (ATK or
                  Elemental Damage). Then move on to situation 2.
                </li>
                <li>
                  <strong>Cost:</strong> 1 Custom Module per Reroll.
                </li>
              </ul>
              <h6>Situation 2</h6>
              <p>
                I have one of the Ideal/Essential effects on my selected gear
                piece.
              </p>
              <p>Follow up 1: Is it on line 2 or line 3?</p>
              <p>
                This is the second best piece to reroll on since it is
                considered cheaper than other pieces, and is faster to finish
                statistically.
              </p>
              <ul>
                <li>
                  <strong>How to reroll:</strong> The target for our gear is 1
                  Elemental Line and 1 ATK line. In this case, let's assume we
                  have an Elemental Line on line 2 or line 3, and now want to
                  get an ATK line on the same piece. First, ensure that this
                  Elemental damage line is above the minimum requirement of
                  attributes %. If it is not, Reset Attributes until it is above
                  the requirement. If it is above the minimum % needed, we will
                  now LOCK the line with the ELEMENTAL DAMAGE effect. Now, we
                  will Change Effects until we get ATK on any one of the other 2
                  lines. After getting the ATK line, we will move on to
                  Rerolling Attributes again until ATK is above or equal to the
                  minimum Attribute %.
                </li>
                <li>
                  <strong>Cost:</strong> 2 Custom Modules per Reroll.
                </li>
              </ul>
              <p>Follow up 2: Is it on line 1?</p>
              <p>
                This is the third best piece to reroll on since it is considered
                more expensive than other pieces, and takes longer to finish
                statistically.
              </p>
              <ul>
                <li>
                  <strong>How to reroll:</strong> There are two ways to reroll
                  this piece, both have their advantages and disadvantages. We
                  will be going through both of them here:
                </li>
                <ul>
                  <li>
                    The effect in line 1 is of lower value than a Tier 11 roll:
                  </li>
                  <ul>
                    <li>
                      The Target for our gear is 1 Elemental Line and 1 ATK
                      line. Do not lock any line. Change Effects till you have
                      one of the Ideal effects (ATK or Elemental Damage) on Line
                      2 or Line 3. Then move up to Situation 1.
                    </li>
                    <li>
                      <strong>Cost:</strong> 1 Custom Module per Reroll.
                    </li>
                  </ul>
                </ul>
                <ul>
                  <li>The effect in line 1 is a Blue line or Black Line:</li>
                  <ul>
                    <li>
                      In this case, you can opt to go down another path. While
                      the above mentioned method of rerolling is statistically
                      the best and safest option, getting a blue line or black
                      line is extremely rare and is of high value. The
                      alternative is as follows. We want to LOCK that effect in
                      Line 1. Let's assume we get a Tier 13 Elemental Damage on
                      Line 1 (26.36%). After Locking, we will now Change Effects
                      until we get ATK on either line 2 or 3. Now, if the ATK
                      roll is below the minimum acceptable attribute, we will
                      Reset Attributes until the ATK line is above the minimum
                      acceptable percentage (%).
                    </li>
                    <li>
                      <strong>Cost:</strong> Each Reroll costs 2 Custom Modules,
                      so keep in mind that this method poses a higher risk.
                      However, if you happen to strike good luck, then this
                      method is a higher reward.
                    </li>
                  </ul>
                </ul>
              </ul>
              <p>
                <strong>Rerolling Attributes</strong>
              </p>
              <p>
                If you happen to luck out and get both of the Essential/Ideal
                effects on your piece from a random reroll, now is the time to
                reroll your attributes. Check if any of the effects are below
                the minimum acceptable attribute %, and Reset Attributes until
                you have at least one of them above that minimum %. If one of
                the rolls has reached the desired % but the other has not, lock
                the effect that has reached the desired %, and Reset Attributes
                until the other effect reaches the minimum acceptable %. You are
                now done with rerolling this piece and are ready to move on to
                another one. Congratulations!
              </p>
              <hr />
              <h5>
                Category 3: The gear piece needs 3 Essential and/or Ideal
                effects.
              </h5>
              <StaticImage
                src="../../../images/nikke/generic/ol_reroll_3.webp"
                alt="Guide"
              />
              <p>
                This category is mostly for competitive players who aim for the
                best of the best rolls, or for those who want to see their
                waifus reach perfection. These methods of rerolling can be
                extremely expensive for players, depending on their luck.
                However, the final result can definitely be worth the expense,
                since the unit maximises their potential.
              </p>
              <p>Coming soon!</p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Final words" />
              <p>
                Rerolling is a luck based system, and due to the poor rock
                income from Special Interception, some bad rng can leave the
                player in bitter taste. Be mindful of where you use your rocks
                since not only is there a lack of income, there are also a lot
                of meta units now which we have to reroll on. Regardless, this
                system has now been in Nikke for quite some time now and it is
                about time we released a guide on it. With the new CP system in
                place, rerolling for more effects is also more critical since we
                want our campaign and PvP Nikkes to be stacked with Overload
                lines to take full advantage of this new system. I hope you
                found our guide helpful. Feel free to join us at{' '}
                <a
                  href="https://discord.gg/prydwen"
                  target="_blank"
                  rel="noreferrer"
                >
                  Prydwen Discord server
                </a>{' '}
                to discuss any improvements, give feedback or get direct help
                from us at Prydwen and their Nikke Community!{' '}
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Before You Start</a>
                </li>
                <li>
                  <a href="#section-2">How to Reroll Efficiently</a>
                </li>
                <li>
                  <a href="#section-3">Final words</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default NikkeGuidesOLIntro;

export const Head: React.FC = () => (
  <Seo
    title="Overload Gear (OL) - Rerolling | NIKKE | Prydwen Institute"
    description="Guide for the Overload Gear (OL) in NIKKE - Rerolling Effects and Attributes."
  />
);
